import { register, init, locale } from "svelte-i18n"
import { browser } from "$app/environment"

register("en", () => import("./translations/en.json"))
register("fr", () => import("./translations/fr.json"))
register("de", () => import("./translations/de.json"))
register("ro", () => import("./translations/ro.json"))
register("ur", () => import("./translations/ur.json"))

// Initialize with a default locale
init({
  fallbackLocale: "en",
  initialLocale: "en",
})

// Function to change locale
export function setLocale(localeCode: string) {
  locale.set(localeCode)
  if (browser) {
    localStorage.setItem("locale", localeCode)
  }
}
